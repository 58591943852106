

const NavBarTop = () => {
    return (
        <>
        <h4>Nav Bar Top</h4>
        </>
    )
}

export default NavBarTop